<template>
  <component
    :is="to ? RouterLink : href ? 'a' : 'button'"
    :id="id"
    v-bind="$attrs"
    ref="buttonRef"
    class="ds-button"
    :class="buttonClasses"
    :to="to"
    :href="href"
    :disabled="disabled || loading"
    :type="type"
    :target="newtab ? '_blank' : undefined"
    :download="download"
    :title="tooltip"
    :style="props.isTransparent ? 'background-color: transparent' : undefined"
  >
    <DsIcon
      v-if="loading"
      :size="small ? 'small' : 'big'"
      name="loader"
      class="ds-loader-component"
      color="gray300"
      :scale="!!scale ? scale : 1"
    />
    <template v-if="success">
      <svg
        class="success"
        version="1.1"
        viewBox="0 0 130.2 130.2"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:space="preserve"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <polyline
          class="path check"
          fill="none"
          stroke-width="24"
          stroke-linecap="round"
          stroke-miterlimit="10"
          points="100.2,40.2 51.5,88.8 29.8,67.5 "
        />
      </svg>
    </template>
    <span
      v-if="counter"
      class="ds-button-counter"
    >
      <i>{{ counter }}</i>
    </span>
    <DsIcon
      v-else-if="!!icon"
      class="ds-icon-component"
      :name="icon"
      :color="disabled ? 'gray300' : iconColor"
      :color-hover="disabled ? 'gray300' : iconColorHover"
      :rotate="iconRotate"
      :size="small ? 'small' : 'big'"
      :scale="!!scale ? scale : 1"
    />
    <span
      ref="slotRef"
      class="slot-container"
    >
      <slot></slot>
    </span>
    <DsIcon
      v-if="!!rightIcon"
      class="ds-icon-component"
      :class="{
        clickable: !!getCurrentInstance()?.vnode.props?.onRightIconAction
      }"
      :name="rightIcon"
      :color="disabled ? 'gray300' : iconColor"
      :rotate="iconRotate"
      :size="small ? 'small' : 'big'"
      :scale="!!scale ? scale : 1"
      :disabled="isRightIconDisabled"
      @click.stop.prevent="$emit('right-icon-action')"
    />
  </component>
</template>

<script lang="ts" setup>
import { computed, getCurrentInstance, onMounted, PropType, ref } from 'vue';
import { RouteLocationRaw, RouterLink } from 'vue-router';

import { COLORS_PALETTE } from '@/helpers/design-system';

import DsIcon from './DsIcon.vue';

const props = defineProps({
  id: { type: String, default: undefined },
  to: {
    type: [Object, String] as PropType<RouteLocationRaw>,
    default: undefined
  },
  href: { type: String, default: undefined },
  newtab: { type: Boolean, default: false },
  download: { type: [Boolean, String], default: undefined },
  type: {
    type: String as PropType<'button' | 'submit' | 'reset'>,
    default: undefined
  },
  icon: { type: String, default: undefined },
  rightIcon: { type: String, default: undefined },
  iconRotate: { type: [String, Number], default: undefined },
  disabled: { type: Boolean, default: false },
  loading: { type: Boolean, default: false },
  success: { type: Boolean, default: false },
  highlighted: { type: Boolean, default: false },
  color: {
    type: String as PropType<Components.Button.ColorName>,
    default: undefined
  },
  secondary: { type: Boolean, default: false },
  small: { type: Boolean, default: false },
  readonly: { type: Boolean, default: false },
  autofocus: { type: Boolean, default: false },
  customIconColor: { type: String, default: undefined },
  customIconColorHover: { type: String, default: undefined },
  tooltip: { type: String, default: undefined },
  isTransparent: { type: Boolean, default: false },
  isRightIconDisabled: { type: Boolean, default: false },
  counter: { type: Number, default: undefined },
  hasBorder: { type: Boolean, default: false },

  /* deprecated */
  danger: { type: Boolean, default: false },
  gray: { type: Boolean, default: false },
  scale: { type: Number, default: undefined }
});

const emit = defineEmits<{
  (e: 'right-icon-action'): void;
}>();

const buttonRef = ref<HTMLElement>();
const slotRef = ref<HTMLElement>();

const hasText = computed(() => slotRef.value?.innerText.length);

const iconColor = computed(() => {
  if (props.customIconColor) {
    return props.customIconColor;
  }

  if (props.color === 'gray') {
    return 'gray700';
  }

  if (!hasText.value && !props.secondary && props.color !== 'white') {
    return 'white';
  }

  return `${props.color}${props.secondary ? '400' : '200'}`;
});

const iconColorHover = computed(() => {
  if (props.customIconColorHover) {
    return props.customIconColorHover;
  }

  if (props.customIconColor) {
    return props.customIconColor;
  }

  if (props.color === 'gray') {
    return 'gray700';
  }
  return `${props.color}${props.secondary ? '400' : '200'}`;
});

const deprecatedPropsClasses = computed(() => ({
  red: props.danger,
  gray: props.gray
}));

const buttonClasses = computed(() => ({
  ...deprecatedPropsClasses.value,
  secondary: props.secondary,
  primary: !props.secondary,
  [props.color]: !!props.color,
  small: props.small,
  readonly: props.readonly,
  disabled: props.disabled,
  highlighted: props.highlighted,
  bordered: props.hasBorder,
  'ds-button--loading': props.loading,
  'ds-button--success': props.success
}));

const border = computed(() => {
  const color = COLORS_PALETTE[`${props.color}300`] ?? COLORS_PALETTE.gray300;
  return `2px solid ${color}`;
});

const padding = computed(() => {
  if (props.small) {
    return '4px 6px';
  }
  return hasText.value ? '8px 12px' : '8px';
});

const minHeight = computed(() => {
  return props.small ? '24px' : '36px';
});

onMounted(() => {
  if (props.autofocus) {
    buttonRef.value.focus();
  }
});
</script>

<style lang="scss" scoped>
.ds-button {
  @include typo-body-bold;

  cursor: pointer;

  position: relative;

  overflow: hidden;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  min-height: v-bind('minHeight');
  padding: v-bind('padding');

  text-overflow: ellipsis;
  white-space: nowrap;

  border-radius: 12px;

  transition: background-color 0.2s ease-in-out;

  .success {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 20px;
  }

  .path {
    display: none;
    stroke-dasharray: 1000;
    stroke-dashoffset: -100;
  }

  .ds-button-counter {
    display: flex;

    width: 18px;
    min-width: 18px;
    height: 18px;
    min-height: 18px;

    color: v-bind("disabled ? 'gray300': iconColor");

    border: v-bind('border');
    border-radius: 50%;

    > i {
      margin: auto;
      font-size: 8px;
      font-weight: 800;
      line-height: 8px;
    }
  }

  &.bordered {
    outline: v-bind('border');
  }

  &.small {
    @include typo-small-body-bold;

    gap: 5px;
    height: 24px;
    border-radius: 8px;

    .success {
      width: 16px;
    }
  }

  &.readonly {
    pointer-events: none;
  }

  &.disabled,
  &:disabled {
    cursor: not-allowed;
    color: $gray400;
    background-color: $gray100;

    &.white {
      color: $gray300;
      background-color: white;
      box-shadow: inset 0 0 0 1px $gray100;
    }
  }

  &:not(&.disabled, &:disabled) {
    .path {
      stroke: $gray1000;
    }

    &.blue {
      &.primary {
        color: white;
        background-color: $blue500;

        &:hover {
          background-color: $blue400;
        }

        &:focus {
          background-color: $blue400;
          box-shadow: 0px 0px 0px 2px $blue150;
        }
      }

      &.secondary {
        color: $blue600;
        background-color: $blue100;

        &:hover {
          background-color: $blue150;
        }
      }

      .path {
        stroke: white;
      }

      :deep(.ds-loader-component > path) {
        fill: white;
      }
    }

    &.pink {
      &.primary {
        color: white;
        background-color: $pink500;

        &:hover {
          background-color: $pink400;
        }
      }

      &.secondary {
        color: $pink600;
        background-color: $pink150;

        &:hover {
          background-color: $pink150;
        }
      }

      .path {
        stroke: white;
      }

      :deep(.ds-loader-component > path) {
        fill: white;
      }
    }

    &.green {
      &.primary {
        color: white;
        background-color: $green500;

        &:hover {
          background-color: $green400;
        }
      }

      &.secondary {
        color: $green600;
        background-color: $green100;

        &:hover {
          background-color: $green150;
        }
      }

      .path {
        stroke: white;
      }

      :deep(.ds-loader-component > path) {
        fill: white;
      }
    }

    &.red {
      &.primary {
        color: white;
        background-color: $red500;

        &:hover {
          background-color: $red400;
        }
      }

      &.secondary {
        color: $red600;
        background-color: $red100;

        &:hover {
          background-color: $red150;
        }
      }

      .path {
        stroke: white;
      }

      :deep(.ds-loader-component > path) {
        fill: white;
      }
    }

    &.orange {
      &.primary {
        color: white;
        background-color: $orange500;

        &:hover {
          background-color: $orange400;
        }
      }

      &.secondary {
        color: $orange600;
        background-color: $orange100;

        &:hover {
          background-color: $orange150;
        }
      }

      .path {
        stroke: white;
      }

      :deep(.ds-loader-component > path) {
        fill: white;
      }
    }

    &.gray {
      color: $gray1000;
      background-color: $gray100;

      &:hover {
        background-color: $gray150;
      }
    }

    &.white {
      color: $gray1000;
      background-color: white;
      box-shadow: inset 0 0 0 1px $gray100;

      &:hover {
        box-shadow: inset 0 0 0 1px $gray200;
      }

      &:focus {
        outline: 2px solid $gray300;
        box-shadow: inset 0 0 0 1px $gray200;
      }
    }

    :deep(.ds-loader-component > path) {
      fill: $gray1000;
    }
  }
}

.highlighted {
  animation-name: highlight;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

@keyframes highlight {
  0% {
    box-shadow: 0 0 2px rgba($pink500, 0.8);
  }

  50% {
    box-shadow: 0 0 8px rgba($pink500, 0.8);
  }

  100% {
    box-shadow: 0 0 2px rgba($pink500, 0.8);
  }
}

.ds-loader-component {
  position: absolute;
  animation: rotation 750ms linear infinite;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.slot-container {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  line-height: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ds-icon-component.clickable {
  cursor: pointer;
}

.ds-button--loading,
.ds-button--success {
  .ds-icon-component,
  .slot-container {
    opacity: 0;
  }
}

@keyframes btn-status-icon-dash-check {
  0% {
    stroke-dashoffset: -100;
  }

  100% {
    stroke-dashoffset: 900;
  }
}

.ds-button.ds-button--success {
  background-color: $green500 !important;

  .path.check {
    display: block;
    animation: btn-status-icon-dash-check 0.5s 0.1s ease-in-out forwards;
  }
}

:empty {
  display: none;
}
</style>
