import { API } from 'aws-amplify';
import { isEqual } from 'lodash-es';
import { ref, watch } from 'vue';

import { useToastStore } from '@/stores/toast';

export const useLazyLoad = (
  promises: (() => Promise<any>)[],
  cacheTtl = 1000 * 60
) => {
  const toast = useToastStore();

  const isPending = ref(false);
  const lastCallTimestamp = ref<Date>();

  const lastCallParams = ref([]);

  const waitForCurrentRequest = () =>
    new Promise((resolve) => {
      const unwatch = watch(
        isPending,
        (newVal) => {
          if (!newVal) {
            unwatch();
            resolve(true);
          }
        },
        { immediate: true }
      );
    });

  const lazyLoad = async (forceReload = false, callParams?: any[]) => {
    if (isPending.value) {
      await waitForCurrentRequest();
      return;
    }

    const isCacheOutdated =
      !lastCallTimestamp.value ||
      new Date().getTime() - lastCallTimestamp.value?.getTime() > cacheTtl ||
      (!!callParams && !isEqual(lastCallParams.value, callParams));

    if (forceReload || isCacheOutdated) {
      try {
        isPending.value = true;

        await Promise.all(promises.map((promise) => promise()));
        lastCallTimestamp.value = new Date();
        lastCallParams.value = callParams ? [...callParams] : [];
      } catch (error) {
        if (!API.isCancel(error)) {
          toast.showError(error);
        }
      } finally {
        isPending.value = false;
      }
    }
  };

  return {
    isPending,
    lazyLoad,
    lastCallTimestamp
  };
};
