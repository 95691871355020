import { API } from '@aws-amplify/api';
import { Ref } from 'vue';

import {
  AdeoErpcDuplicateCheck,
  ExportCounterpartiesToERPInput,
  GetCounterpartyPaymentOutput,
  GetExportCounterpartiesOutput,
  GetExportCounterpartiesQuery,
  GetFullCompanyInfoBySiretOutput,
  GetVendorOnboardingCounterpartyOutput,
  InviteVendorOnboardingBody,
  PatchVendorOnboardingBody,
  PatchVendorOnboardingBodyV2,
  PostBicInput,
  PostBicOutput,
  PostRetryExportCounterpartiesToERPInput,
  PostVendorOnboardingCopyLinkBody,
  PostVendorOnboardingCopyLinkOutput,
  RejectMotiveEnum,
  RetrieveCounterpartyToApproveNumberOutput,
  RetrieveVendorOnboardingOutput,
  SearchCompanyInfoByNameOutput,
  SubmitVendorOnboardingBody,
  VendorContactBody,
  VendorContactOutput,
  VendorEnrichmentInput,
  VendorEnrichmentOutput,
  VendorEnrichmentSectionsOutput,
  VendorManagementForm,
  VendorManagementFormInput,
  VendorManagementFormsOutput,
  VendorRequestFormStepAndValueOutput
} from '@/api/schemas/counterparty';
import {
  BeneficiaryEntityType,
  CounterpartyStatus,
  CounterpartyType,
  CountryCode,
  CreateCounterpartyDetailsInput,
  CreateCounterpartyDetailsOutput,
  CreateCounterpartyInput,
  CreateCounterpartyOutput,
  ReassignCounterpartyDetailsInput,
  RetrieveCounterpartiesInput,
  RetrieveCounterpartiesOutput,
  RetrieveCounterpartyOutput,
  RetrieveCounterpartyOutputV2,
  RetrieveForms,
  UpdateCounterpartyInput
} from '@/custom-types/counterparties';
import { getTenantId, getUserJwtToken } from '@/helpers/amplify';
import { removeUndefinedNullFromObject } from '@/helpers/object';

import { RetrieveManyFormStepsOutput } from '../intake/form-version-steps';
import {
  CounterpartyWorkflowInput,
  RetrieveCounterpartyWorkflowOutput
} from '../schemas/approval';

export async function getCounterparties(
  filtersParams?: RetrieveCounterpartiesInput,
  promiseToCancelRef?: Ref<Promise<any>>
): Promise<RetrieveCounterpartiesOutput> {
  if (promiseToCancelRef?.value) {
    API.cancel(promiseToCancelRef.value);
  }

  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const queryStringParameters = filtersParams
    ? removeUndefinedNullFromObject(filtersParams)
    : undefined;

  const promise = API.get(
    'counterparty',
    `/rest/v1/${tenantId}/counterparties`,
    {
      queryStringParameters,
      headers
    }
  );

  if (promiseToCancelRef) {
    promiseToCancelRef.value = promise;
  }

  return promise;
}

export async function createCounterparty(
  counterparty: CreateCounterpartyInput,
  options: { raiseError: boolean } = { raiseError: false }
): Promise<CreateCounterpartyOutput> {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };
  const body = counterparty;

  const queryStringParameters: any = {};
  if (options.raiseError === true) {
    queryStringParameters.raise_error = true;
  }
  if (options.raiseError === false) {
    queryStringParameters.raise_error = false;
  }
  return API.post('counterparty', `/rest/v1/${tenantId}/counterparty`, {
    headers,
    body,
    queryStringParameters
  });
}

export async function deleteCounterparty(
  counterpartiesIdList: number[]
): Promise<{ ids: number[] }> {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };
  const body = { ids: counterpartiesIdList };

  return API.patch('counterparty', `/rest/v1/${tenantId}/counterparties`, {
    body,
    headers
  });
}

export async function rejectCounterparty(
  workflowInstanceId: number,
  stepInstanceIds: number[],
  motive: RejectMotiveEnum,
  details: string
): Promise<{ ids: number[] }> {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };
  const body = { step_instance_ids: stepInstanceIds, motive, details };

  return API.post(
    'counterparty',
    `/rest/v2/${tenantId}/counterparties/workflow-instances/${workflowInstanceId}/reject`,
    {
      body,
      headers
    }
  );
}

export async function getCounterparty(
  counterpartyId: number
): Promise<RetrieveCounterpartyOutput> {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.get(
    'counterparty',
    `/rest/v1/${tenantId}/counterparties/${counterpartyId}`,
    { headers }
  );
}

export async function getCounterpartyV2(
  counterpartyId: number
): Promise<RetrieveCounterpartyOutputV2> {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.get(
    'counterparty',
    `/rest/v2/${tenantId}/counterparties/${counterpartyId}`,
    { headers }
  );
}

export async function getCounterpartyToApproveNumber(): Promise<RetrieveCounterpartyToApproveNumberOutput> {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.get('counterparty', `/rest/v1/${tenantId}/counterparties/count`, {
    headers,
    queryStringParameters: {
      approval_status_in: CounterpartyStatus.APPROVAL
    }
  });
}

export async function updateCounterparty(
  counterpartyId: number,
  counterparty: UpdateCounterpartyInput
) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };
  const body = counterparty;

  return API.put(
    'counterparty',
    `/rest/v1/${tenantId}/counterparties/${counterpartyId}`,
    { headers, body }
  );
}

export async function approveCounterparty(counterpartyId: number) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.post(
    'counterparty',
    `/rest/v1/${tenantId}/counterparties/${counterpartyId}/approve`,
    { headers }
  );
}

export async function approveCounterparties(counterpartyIds: number[]) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.post(
    'counterparty',
    `/rest/v1/${tenantId}/counterparties/approve`,
    { headers, body: { counterparty_ids: counterpartyIds } }
  );
}

export async function getFormsCounterpartyByCountry(
  countryCode: CountryCode,
  type: BeneficiaryEntityType
): Promise<RetrieveForms> {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.get(
    'counterparty',
    `/rest/v1/${tenantId}/get-forms/${countryCode}?type=${type}`,
    { headers }
  );
}

export async function createCounterpartyPayment(
  counterpartyId: number,
  counterparty: CreateCounterpartyDetailsInput,
  options: { raiseError: boolean } = { raiseError: false }
): Promise<CreateCounterpartyDetailsOutput> {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };
  const body = counterparty;

  const queryStringParameters: any = {};
  if (options.raiseError === true) {
    queryStringParameters.raise_error = true;
  }
  if (options.raiseError === false) {
    queryStringParameters.raise_error = false;
  }

  return API.post(
    'counterparty',
    `/rest/v1/${tenantId}/counterparties/${counterpartyId}/counterparty-payment`,
    { headers, body, queryStringParameters }
  );
}

export async function reassignCounterpartyDetails(
  counterpartyPaymentId: number,
  detailsToAssign: ReassignCounterpartyDetailsInput
) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };
  const body = {
    counterparty_payment_id: counterpartyPaymentId,
    counterparty_id: detailsToAssign.counterparty_id
  };

  return API.patch(
    'counterparty',
    `/rest/v1/${tenantId}/counterparty-payment/reassign`,
    { headers, body }
  );
}

export async function deleteCounterpartyPayment(
  counterpartyPaymentIdList: number[]
) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };
  const body = { ids: counterpartyPaymentIdList };

  return API.del('counterparty', `/rest/v1/${tenantId}/counterparty-payment`, {
    body,
    headers
  });
}

export async function getCounterpartyPaymentById(
  paymentIdList: number[],
  withInactiveCtpPayment = false,
  withInactiveCTP = false
) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const query = `/rest/v1/${tenantId}/counterparty-payment`;

  const queryStringParameters = {
    counterparty_payment_ids: paymentIdList,
    with_inactive_counterparty_payment: withInactiveCtpPayment,
    with_inactive_counterparty: withInactiveCTP
  };

  return API.get('counterparty', query, { queryStringParameters, headers });

  // return API.get('counterparty', `/rest/v1/${tenantId}/counterparty-payment`, { headers })
}

export async function postCounterpartyPaymentById(paymentIdList: number[]) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const query = `/rest/v1/${tenantId}/counterparty-payment/find`;

  const body = {
    counterparty_payment_ids: paymentIdList,
    number: 1000000,
    page_nb: 1
  };

  return API.post('counterparty', query, { body, headers });

  // return API.get('counterparty', `/rest/v1/${tenantId}/counterparty-payment`, { headers })
}

export async function getCounterpartyPayment(query: {
  name?: string;
  number: number;
  page_nb: number;
  relationship_in?: CounterpartyType[];
  counterparty_ids?: number[];
}): Promise<GetCounterpartyPaymentOutput> {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const queryStringParameters = query;

  if (!queryStringParameters?.relationship_in?.length) {
    queryStringParameters.relationship_in = [
      CounterpartyType.VENDOR,
      CounterpartyType.EMPLOYEE,
      CounterpartyType.CUSTOMER
    ];
  }

  return API.get('counterparty', `/rest/v1/${tenantId}/counterparty-payment`, {
    queryStringParameters,
    headers
  });
}

export async function getCounterpartiesPayflowsUnknown() {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.get(
    'counterparty',
    `/rest/v1/${tenantId}/counterparties/payflows-unknown?with_ctp_payments=false`,
    {
      headers
    }
  );
}

export async function inviteVendorOnboarding(
  body: InviteVendorOnboardingBody
): Promise<void> {
  const headers = { token: await getUserJwtToken() };
  const tenantId = await getTenantId();

  return API.post(
    'counterparty',
    `/rest/v1/${tenantId}/vendor-onboarding/invite`,
    {
      headers,
      body
    }
  );
}

export async function getVendorOnboarding(
  tenantId: number,
  token: string
): Promise<RetrieveVendorOnboardingOutput> {
  if (!tenantId) throw new Error('tenantId is required');
  if (!token) throw new Error('token is required');

  const headers = { 'authorization-token': `Bearer ${token}` };

  return API.get('counterparty', `/rest/v1/${tenantId}/vendor-onboarding`, {
    headers
  });
}

export async function submitVendorOnboarding(
  body: SubmitVendorOnboardingBody,
  tenantId: number,
  token: string
): Promise<any> {
  const headers = { 'authorization-token': `Bearer ${token}` };

  return API.post(
    'counterparty',
    `/rest/v1/${tenantId}/vendor-onboarding/submit`,
    {
      headers,
      body
    }
  );
}

export async function postVendorOnboardingCopyLink(
  body: PostVendorOnboardingCopyLinkBody
): Promise<PostVendorOnboardingCopyLinkOutput> {
  const headers = { token: await getUserJwtToken() };
  const tenantId = await getTenantId();

  return API.post(
    'counterparty',
    `/rest/v1/${tenantId}/vendor-onboarding/copy-link`,
    {
      body,
      headers
    }
  );
}

export async function postVendorOnboardingReinvite(
  tenantId: number,
  token: string
): Promise<any> {
  if (!tenantId) throw new Error('tenantId is required');
  if (!token) throw new Error('token is required');

  const headers = { 'authorization-token': `Bearer ${token}` };

  return API.post(
    'counterparty',
    `/rest/v1/${tenantId}/vendor-onboarding/reinvite`,
    {
      headers
    }
  );
}

export async function exportCounterpartiesToERP(
  body: ExportCounterpartiesToERPInput
) {
  const headers = { token: await getUserJwtToken() };
  const tenantId = await getTenantId();

  return API.post(
    'counterparty',
    `/rest/v1/${tenantId}/counterparties/export-to-erp`,
    {
      body,
      headers
    }
  );
}

export async function retryExportCounterpartiesToERP(
  body: PostRetryExportCounterpartiesToERPInput
) {
  const headers = { token: await getUserJwtToken() };
  const tenantId = await getTenantId();

  return API.post(
    'counterparty',
    `/rest/v1/${tenantId}/counterparties/export-to-erp/retry`,
    {
      body,
      headers
    }
  );
}

export async function postBic(
  body: PostBicInput,
  forcedTenantId?: number,
  forcedToken?: string
): Promise<PostBicOutput> {
  let headers = {};
  if (forcedToken) {
    headers = { 'authorization-token': `Bearer ${forcedToken}` };
  } else {
    headers = { token: await getUserJwtToken() };
  }

  const tenantId = forcedTenantId || (await getTenantId());

  return API.post('counterparty', `/rest/v1/${tenantId}/counterparty/bic`, {
    body,
    headers
  });
}

export async function getExportCounterparties(
  getExportCounterpartiesQuery: GetExportCounterpartiesQuery
): Promise<GetExportCounterpartiesOutput> {
  const headers = { token: await getUserJwtToken() };
  const tenantId = await getTenantId();
  const queryStringParameters = getExportCounterpartiesQuery;
  return API.get('counterparty', `/rest/v1/${tenantId}/counterparties/export`, {
    queryStringParameters,
    headers
  });
}

export async function getVendorOnboardingCounterparty(
  tenantId,
  token
): Promise<GetVendorOnboardingCounterpartyOutput> {
  const headers = { 'authorization-token': `Bearer ${token}` };

  return API.get(
    'counterparty',
    `/rest/v1/${tenantId}/vendor-onboarding/counterparty`,
    {
      headers
    }
  );
}

export async function patchVendorOnboarding(
  tenantId: number,
  token: string,
  vendorOnboardingId: number,
  body: PatchVendorOnboardingBody
): Promise<GetVendorOnboardingCounterpartyOutput> {
  const headers = { 'authorization-token': `Bearer ${token}` };

  if (!vendorOnboardingId) throw new Error('vendorOnboardingId is required');

  return API.patch(
    'counterparty',
    `/rest/v1/${tenantId}/vendor-onboarding/${vendorOnboardingId}`,
    {
      headers,
      body
    }
  );
}

export interface FormInstanceInvoicesFile {
  key: string;
  bucket: string;
}
export interface PostFormInstanceInvoicesFilesFromVendorOnboardingBody {
  files: FormInstanceInvoicesFile[];
}

export async function postFormInstanceInvoicesFilesFromVendorOnboarding(
  tenantId,
  token: string,
  body: PostFormInstanceInvoicesFilesFromVendorOnboardingBody
): Promise<any> {
  const headers = { 'authorization-token': `Bearer ${token}` };

  return API.post(
    'intake',
    `/rest/v2/${tenantId}/form-instance-invoices/files-from-vendor-onboarding`,
    {
      headers,
      body
    }
  );
}

export async function createVendorContact(
  body: VendorContactBody
): Promise<VendorContactOutput> {
  const headers = { token: await getUserJwtToken() };
  const tenantId = await getTenantId();

  return API.post('counterparty', `/rest/v2/${tenantId}/contacts`, {
    headers,
    body
  });
}

export async function updateVendorContact(
  contactId: number,
  body: VendorContactBody
): Promise<VendorContactOutput> {
  const headers = { token: await getUserJwtToken() };
  const tenantId = await getTenantId();

  return API.patch(
    'counterparty',
    `/rest/v2/${tenantId}/contacts/${contactId}`,
    {
      headers,
      body
    }
  );
}

export async function deleteVendorContact(contactId: number): Promise<void> {
  const headers = { token: await getUserJwtToken() };
  const tenantId = await getTenantId();

  return API.del('counterparty', `/rest/v2/${tenantId}/contacts/${contactId}`, {
    headers
  });
}

// VENDOR MANAGEMENT FORM
export async function retrieveVendorManagementForms(
  preferred_language?: string,
  forceAllEntities?: boolean
): Promise<VendorManagementFormsOutput> {
  const headers = { token: await getUserJwtToken() };
  const tenantId = await getTenantId();
  const queryStringParameters: {
    preferred_language: string;
    all_forms?: boolean;
  } = {
    preferred_language
  };
  if (forceAllEntities) {
    queryStringParameters.all_forms = true;
  }

  return API.get(
    'counterparty',
    `/rest/v2/${tenantId}/vendor-management-forms`,
    {
      headers,
      queryStringParameters
    }
  );
}

export async function createVendorManagementForm(
  body: VendorManagementFormInput
): Promise<VendorManagementForm> {
  const headers = { token: await getUserJwtToken() };
  const tenantId = await getTenantId();

  return API.post(
    'counterparty',
    `/rest/v2/${tenantId}/vendor-management-forms`,
    {
      headers,
      body
    }
  );
}

export async function updateVendorManagementForm(
  id,
  body: VendorManagementFormInput
): Promise<VendorManagementForm> {
  const headers = { token: await getUserJwtToken() };
  const tenantId = await getTenantId();

  return API.put(
    'counterparty',
    `/rest/v2/${tenantId}/vendor-management-forms/${id}`,
    {
      headers,
      body
    }
  );
}

export async function deleteVendorManagementForm(id) {
  const headers = { token: await getUserJwtToken() };
  const tenantId = await getTenantId();

  return API.del(
    'counterparty',
    `/rest/v2/${tenantId}/vendor-management-forms/${id}`,
    {
      headers
    }
  );
}

// VENDOR REQUEST FORM
export async function retrieveVendorRequestForm(
  formId: number
): Promise<RetrieveManyFormStepsOutput> {
  const headers = { token: await getUserJwtToken() };
  const tenantId = await getTenantId();
  return await API.get(
    'counterparty',
    `/rest/v2/${tenantId}/vendor-management-forms/${formId}/vendor-request-forms`,
    {
      headers
    }
  );
}

export async function updateVendorRequestForm(
  formId: number,
  body
): Promise<RetrieveManyFormStepsOutput> {
  const headers = { token: await getUserJwtToken() };
  const tenantId = await getTenantId();

  return API.put(
    'counterparty',
    `/rest/v2/${tenantId}/vendor-management-forms/${formId}/vendor-request-forms`,
    {
      headers,
      body
    }
  );
}

// VENDOR ENRICHMENT FORM
export async function retrieveVendorEnrichment(
  formId: number
): Promise<VendorEnrichmentOutput> {
  const headers = { token: await getUserJwtToken() };
  const tenantId = await getTenantId();

  return API.get(
    'counterparty',
    `/rest/v2/${tenantId}/vendor-management-forms/${formId}/vendor-enrichment-forms`,
    {
      headers
    }
  );
}

export async function updateVendorEnrichment(
  formId: number,
  body: VendorEnrichmentInput
) {
  const headers = { token: await getUserJwtToken() };
  const tenantId = await getTenantId();

  return API.put(
    'counterparty',
    `/rest/v2/${tenantId}/vendor-management-forms/${formId}/vendor-enrichment-forms`,
    {
      headers,
      body
    }
  );
}

// VENDOR APPROVAL WORKFLOW
export async function retrieveVendorApproval(
  formId: number
): Promise<RetrieveCounterpartyWorkflowOutput> {
  const headers = { token: await getUserJwtToken() };
  const tenantId = await getTenantId();
  return API.get(
    'counterparty',
    `/rest/v2/${tenantId}/vendor-management-forms/${formId}/vendor-approval-forms`,
    {
      headers
    }
  );
}

export async function updateVendorApproval(
  formId,
  body: CounterpartyWorkflowInput
): Promise<{ workflow_id: number }> {
  const headers = { token: await getUserJwtToken() };
  const tenantId = await getTenantId();
  return API.put(
    'counterparty',
    `/rest/v2/${tenantId}/vendor-management-forms/${formId}/vendor-approval-forms`,
    {
      headers,
      body
    }
  );
}

// VENDOR SINGLE PAGE
export async function retrieveVendorWorkflowInstances(counterpartyId: number) {
  const headers = { token: await getUserJwtToken() };
  const tenantId = await getTenantId();

  return API.get(
    'counterparty',
    `/rest/v2/${tenantId}/counterparties/workflow-instances/${counterpartyId}`,
    {
      headers
    }
  );
}

// VENDOR FORM INSTANCE
export async function createVendorFormInstanceDraft(formid) {
  const headers = { token: await getUserJwtToken() };
  const tenantId = await getTenantId();
  return API.post(
    'counterparty',
    `/rest/v2/${tenantId}/vendor-form-instances/draft`,
    {
      headers,
      queryStringParameters: { vendor_management_form_id: formid }
    }
  );
}

export async function retrieveVendorFormInstanceFirstStep(
  formId: number,
  language: string
): Promise<VendorRequestFormStepAndValueOutput> {
  const headers = { token: await getUserJwtToken() };
  const tenantId = await getTenantId();
  return API.get(
    'counterparty',
    `/rest/v2/${tenantId}/vendor-management-forms/${formId}/vendor-request-forms/steps/first`,
    {
      headers,
      queryStringParameters: { preferred_language: language }
    }
  );
}

export async function saveVendorFormInstanceStep(
  formInstanceId: number,
  body
): Promise<void> {
  const headers = { token: await getUserJwtToken() };
  const tenantId = await getTenantId();
  return API.put(
    'counterparty',
    `/rest/v2/${tenantId}/vendor-form-instances/${formInstanceId}/step-value`,
    {
      headers,
      body
    }
  );
}

export async function retrieveVendorFormInstanceNextStep(
  formInstanceId: number,
  currentStepId: number,
  language: string
): Promise<VendorRequestFormStepAndValueOutput> {
  const headers = { token: await getUserJwtToken() };
  const tenantId = await getTenantId();
  return API.get(
    'counterparty',
    `/rest/v2/${tenantId}/vendor-request-steps/${currentStepId}/next`,
    {
      headers,
      queryStringParameters: {
        vendor_form_instance_id: formInstanceId,
        preferred_language: language
      }
    }
  );
}

export async function submitVendorFormInstance(
  formInstanceId: number
): Promise<{ counterparty_id: number; vendor_form_instance_id: number }> {
  const headers = { token: await getUserJwtToken() };
  const tenantId = await getTenantId();
  return API.put(
    'counterparty',
    `/rest/v2/${tenantId}/vendor-form-instances/${formInstanceId}/submit`,
    { headers }
  );
}

export async function createVendorDraft(): Promise<{ id: number }> {
  const headers = { token: await getUserJwtToken() };
  const tenantId = await getTenantId();
  return API.post('counterparty', `/rest/v2/${tenantId}/counterparties/draft`, {
    headers
  });
}

// COUNTERPARTY SEARCH
let searchCompanyInfoByNamePromise: Promise<SearchCompanyInfoByNameOutput>;
export async function searchCompanyInfoByName(
  searchQuery: string
): Promise<SearchCompanyInfoByNameOutput> {
  const headers = { token: await getUserJwtToken() };
  const tenantId = await getTenantId();

  if (searchCompanyInfoByNamePromise) {
    API.cancel(searchCompanyInfoByNamePromise);
  }

  searchCompanyInfoByNamePromise = API.get(
    'counterparty',
    `/rest/v2/${tenantId}/counterparties/info_by_name`,
    {
      headers,
      queryStringParameters: { name: searchQuery, page_size: 10 }
    }
  );

  return searchCompanyInfoByNamePromise;
}

export async function getFullCompanyInfoBySiret(
  registration_number: string
): Promise<GetFullCompanyInfoBySiretOutput> {
  const headers = { token: await getUserJwtToken() };
  const tenantId = await getTenantId();
  return API.get(
    'counterparty',
    `/rest/v2/${tenantId}/counterparties/info_by_siret`,
    {
      headers,
      queryStringParameters: { registration_number }
    }
  );
}

export async function checkAdeoErpcDuplicates(
  tax_id: string,
  form_id: number
): Promise<AdeoErpcDuplicateCheck> {
  const headers = { token: await getUserJwtToken() };
  const tenantId = await getTenantId();
  return API.get(
    'counterparty',
    `/rest/v2/${tenantId}/adeo/erpc/check_supplier_exists`,
    {
      headers,
      queryStringParameters: { tax_id, form_id }
    }
  );
}

export async function translateVendorRequestForm(
  formId: number,
  language: string
) {
  const headers = { token: await getUserJwtToken() };
  const tenantId = await getTenantId();

  return API.put(
    'counterparty',
    `/rest/v2/${tenantId}/vendor-management-forms/${formId}/vendor-request-forms/translations`,
    { headers, body: { alternative_language: language } }
  );
}

export async function translateVendorEnrichmentForm(
  formId: number,
  language: string
) {
  const headers = { token: await getUserJwtToken() };
  const tenantId = await getTenantId();

  return API.put(
    'counterparty',
    `/rest/v2/${tenantId}/vendor-management-forms/${formId}/vendor-enrichment-forms/translations`,
    { headers, body: { alternative_language: language } }
  );
}

export async function getVendorEnrichmentSections(
  counterpartyId: number
): Promise<VendorEnrichmentSectionsOutput> {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.get(
    'counterparty',
    `/rest/v2/${tenantId}/vendor/${counterpartyId}/enrichment`,
    { headers }
  );
}

export async function putVendorEnrichmentSections(
  counterpartyId: number,
  body: PatchVendorOnboardingBodyV2
): Promise<void> {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.put(
    'counterparty',
    `/rest/v2/${tenantId}/vendor/${counterpartyId}/enrichment`,
    { headers, body }
  );
}
