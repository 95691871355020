<template>
  <div>
    <div v-if="!!axisData">
      <div
        v-for="(node, index) in axisData.nodes"
        :key="'childNode-' + node.id"
        style="width: 100%"
      >
        <AnalyticalAxisChildTitle
          :node="node"
          :is-deployed="isDeployed[index]"
          :is-checked="isObjectInArrayByField(axesChecked, 'id', node.id)"
          :single-select="singleSelect"
          :filter-by-erp="filterByErp"
          @click.prevent="isDeployed[index] = !isDeployed[index]"
          @update:checkAction="(e) => handleCheckAction(e, index, node)"
        />
        <AnalyticalAxisChild
          v-show="isDeployed[index]"
          :ref="(elt) => setItemRef(elt, node.id)"
          :node="node"
          :single-select="singleSelect"
          :axis-name="axisData.name"
          :filter-by-erp="filterByErp"
          @update:addParentId="addNodeId(node)"
          @update:removeParentId="removeNodeId(node.id, true)"
          @on-close-axis="$emit('onCloseAxis')"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { cloneDeep } from 'lodash-es';
import { onMounted, provide, ref, watch } from 'vue';

import AnalyticalAxisChild from '@/components/Transactions/TransactionCategory/AnalyticalAxisChild.vue';
import AnalyticalAxisChildTitle from '@/components/Transactions/TransactionCategory/AnalyticalAxisChildTitle.vue';
import { AnalyticalAxesModel } from '@/custom-types/analytical-axes';
import { isObjectInArrayByField } from '@/helpers/array';

const props = defineProps<{
  axisData: AnalyticalAxesModel;
  singleSelect?: boolean;
  axesChecked: any[];
  filterByErp?: boolean;
}>();

const emit = defineEmits(['update:analyticalAxisSelected', 'onCloseAxis']);

const isDeployed = ref(new Array(props.axisData.nodes?.length).fill(false));
const nodeRefs = ref([]);
const idTitleChecked = ref([]);
const copyOfAxesChecked = ref(cloneDeep(props.axesChecked));
const radioChecked = ref(null);

provide('copyOfAxesChecked', copyOfAxesChecked);
provide('radioChecked', radioChecked);

const handleCheckAction = (value, index, node) => {
  updateIdListAxisChecked(value, index, node);
  if (props.singleSelect && value) {
    emit('onCloseAxis');
  }
  if (value) {
    isDeployed.value[index] = value;
  }
};

const updateIdListAxisChecked = (isChecked, index, node) => {
  if (isChecked && props.singleSelect) {
    copyOfAxesChecked.value = [];
    idTitleChecked.value = [];
  }
  if (isChecked) {
    if (!isObjectInArrayByField(copyOfAxesChecked.value, 'id', node.id)) {
      copyOfAxesChecked.value = [
        ...copyOfAxesChecked.value,
        {
          id: node.id,
          name: node.name
        }
      ];
    }
    idTitleChecked.value = [...idTitleChecked.value, node.id];
    if (!props.singleSelect) {
      nodeRefs.value[index].elt.addAllNodeId();
    }
  } else {
    removeNodeId(node.id);
  }
};

const setItemRef = (elt, id) => {
  const current = nodeRefs.value.find((node) => node.id === id);
  if (!current) {
    nodeRefs.value.push({
      id,
      elt
    });
  }
};

const addNodeId = (node) => {
  if (props.singleSelect) {
    idTitleChecked.value = [];
  }

  if (!idTitleChecked.value.includes(node.id)) {
    idTitleChecked.value = [...idTitleChecked.value, node.id];
  }

  if (!isObjectInArrayByField(copyOfAxesChecked.value, 'id', node.id)) {
    copyOfAxesChecked.value = [
      ...copyOfAxesChecked.value,
      {
        id: node.id,
        name: node.name
      }
    ];
  }
};

const addAllNodeId = () => {
  props.axisData.nodes.forEach((node) => {
    if (!isObjectInArrayByField(copyOfAxesChecked.value, 'id', node.id)) {
      copyOfAxesChecked.value = [
        ...copyOfAxesChecked.value,
        {
          id: node.id,
          name: node.name
        }
      ];
    }
  });
  nodeRefs.value.map((elt) => elt.elt.addAllNodeId());
};

const removeNodeId = (nodeId, avoidCheckingChild = false) => {
  if (props.singleSelect && !!radioChecked.value) {
    radioChecked.value = null;
  }

  if (isObjectInArrayByField(copyOfAxesChecked.value, 'id', nodeId)) {
    removeFromArrayObject('id', nodeId);
  }

  if (!avoidCheckingChild) {
    const tmpNode = nodeRefs.value.find((elt) => elt.id === nodeId);
    if (tmpNode) {
      tmpNode.elt.removeAllNodeId();
    }
  }
  removeFromArray(nodeId);
};

const removeAllNodeId = () => {
  if (props.singleSelect && !!radioChecked.value) {
    radioChecked.value = null;
  }
  props.axisData.nodes.forEach((node) => {
    if (isObjectInArrayByField(copyOfAxesChecked.value, 'id', node.id)) {
      removeFromArrayObject('id', node.id);
    }
  });
  nodeRefs.value.map((elt) => elt.elt.removeAllNodeId());
};

const removeFromArrayObject = (field, value) => {
  const index = copyOfAxesChecked.value.map((e) => e[field]).indexOf(value);
  copyOfAxesChecked.value.splice(index, 1);
  copyOfAxesChecked.value = [...copyOfAxesChecked.value];
};

const removeFromArray = (value) => {
  const index = idTitleChecked.value.indexOf(value);
  idTitleChecked.value.splice(index, 1);
  idTitleChecked.value = [...idTitleChecked.value];
};

const deployAll = () => {
  isDeployed.value = new Array(props.axisData.nodes?.length).fill(true);
  nodeRefs.value.forEach((elt) => {
    elt.elt.deployAll();
  });
};

const unDeployAll = () => {
  isDeployed.value = new Array(props.axisData.nodes?.length).fill(false);
  nodeRefs.value.forEach((elt) => {
    elt.elt.unDeployAll();
  });
};

watch(copyOfAxesChecked, () => {
  emit('update:analyticalAxisSelected', copyOfAxesChecked.value);
});

onMounted(() => {
  const retrieveNodeChecked = props.axesChecked[props.axesChecked.length - 1];
  props.axisData.nodes.forEach((node, index) =>
    isObjectInArrayByField(props.axesChecked, 'id', node.id)
      ? updateIdListAxisChecked(true, index, node)
      : null
  );
  if (retrieveNodeChecked) {
    radioChecked.value = retrieveNodeChecked.id;
  }
});

defineExpose({ addAllNodeId, removeAllNodeId, deployAll, unDeployAll });
</script>
