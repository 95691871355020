<template>
  <div
    v-if="items.length && !isContentOverflow"
    ref="mainAnalyticalChip"
    class="breadcrumb-box"
    :class="[
      colorName,
      tone,
      { disabled: disabled },
      { 'fill-icon': !noFillIcon }
    ]"
    @mouseover="isElementHover = true"
    @mouseleave="isElementHover = false"
  >
    <template
      v-for="(item, index) in displayedItems"
      :key="index"
    >
      <DsIcon
        v-if="icons?.[index]"
        :class="{ 'fill-icon': !noFillIcon }"
        :name="icons[index]"
        size="small"
      />
      <span
        ref="elementToCheck"
        style="overflow: hidden; text-overflow: ellipsis"
      >
        {{ item }}
      </span>
      <DsIcon
        v-if="index < displayedItems.length - 1"
        name="chevron-right"
        size="small"
      />
    </template>
    <DsIcon
      v-if="noPop === false"
      name="cross"
      size="small"
      @click.stop="emit('pop')"
    />
  </div>
  <PfTooltip
    v-else-if="items.length && isContentOverflow"
    :max-width="400"
    :position="tooltipPosition"
  >
    <template #content>
      <div
        class="breadcrumb-box"
        :class="[
          colorName,
          tone,
          { disabled: disabled },
          { 'fill-icon': !noFillIcon }
        ]"
        @mouseover="isElementHover = true"
        @mouseleave="isElementHover = false"
      >
        <template
          v-for="(item, index) in displayedItems"
          :key="index"
        >
          <DsIcon
            v-if="icons?.[index]"
            :class="{ 'fill-icon': !noFillIcon }"
            :name="icons[index]"
            size="small"
          />
          <span
            ref="elementToCheck"
            style="overflow: hidden; text-overflow: ellipsis"
          >
            {{ item }}
          </span>
          <DsIcon
            v-if="index < displayedItems.length - 1"
            name="chevron-right"
            size="small"
          />
        </template>
        <DsIcon
          v-if="noPop === false"
          name="cross"
          size="small"
          @click.stop="emit('pop')"
        />
      </div>
    </template>
    <template #tooltip-text>
      {{ completePath }}
    </template>
  </PfTooltip>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue';

import { useSettingsAnalyticalAxesStore } from '@/stores/views/settingsAnalyticalAxes';

import PfTooltip from '../NewDesignSystem/PfTooltip.vue';
import DsIcon from './DsIcon.vue';

const props = withDefaults(
  defineProps<{
    items: string[];
    icons?: string[];
    color?: string;
    noPop?: boolean;
    disabled?: boolean;
    cursor?: string;
    noFillIcon?: boolean;
    tooltipPosition?: 'top' | 'bottom' | 'left' | 'right';
    maxDisplayedItems?: number;
  }>(),
  {
    items: () => [],
    icons: () => [],
    color: '',
    noPop: false,
    disabled: false,
    cursor: 'pointer',
    noFillIcon: false,
    tooltipPosition: 'top',
    maxDisplayedItems: Infinity
  }
);

const emit = defineEmits(['pop']);
const settingsAnalyticalAxes = useSettingsAnalyticalAxesStore();

const colorName = ref(null);
const tone = ref(null);
const isElementHover = ref(false);
const isContentOverflow = ref(false);
const mainAnalyticalChip = ref<HTMLElement>(null);
const leftPosition = ref(0);
const elementToCheck = ref<HTMLElement>(null);
const tooltip = ref<HTMLElement>(null);
const completePath = ref('');

const displayedItems = computed(() => {
  if (props.items.length <= props.maxDisplayedItems) {
    return props.items;
  }

  const firstItem = props.items[0];
  const lastItem = props.items[props.items.length - 1];
  const middleItemsCount = props.maxDisplayedItems - 2;
  const middleStartIndex = Math.floor(
    (props.items.length - middleItemsCount) / 2
  );
  return [firstItem, '...', props.items[props.items.length - 2], lastItem];
});

onMounted(() => {
  getColors();
  completePath.value = props.items.join(' > ');
});

watch(
  () => props.color,
  () => {
    getColors();
  }
);

watch(isElementHover, () => {
  let size = 0;
  const offset = 5;
  if (!mainAnalyticalChip.value) return;
  Array.from(mainAnalyticalChip.value.children).forEach((elt) => {
    size += elt.scrollWidth + offset;
  });

  if (size > mainAnalyticalChip.value.scrollWidth) {
    isContentOverflow.value = true;
  } else {
    isContentOverflow.value = false;
  }
});

const getColors = () => {
  switch (props.color) {
    case '#f9d4cf':
    case '#e54f3a':
    case '#a63f34':
    case 'red':
      colorName.value = 'red';
      break;
    case '#fef1e2':
    case '#fde7cb':
    case '#f29829':
    case '#b07229':
    case 'orange':
      colorName.value = 'orange';
      break;
    case '#fef0bc':
    case '#fdf3d1':
    case '#f5ce4e':
    case '#b29842':
    case '#907C3C':
    case 'yellow':
      colorName.value = 'yellow';
      break;
    case '#d5eacb':
    case '#54ab2a':
    case '#447f28':
    case '#e8f3e2':
    case 'green':
      colorName.value = 'green';
      break;
    case '#d8f2fa':
    case '#60c8e9':
    case '#4a93ae':
    case 'cyan':
      colorName.value = 'cyan';
      break;
    case '#d2d9ff':
    case '#425eff':
    case '#3549be':
    case '#e6eaff':
    case 'blue':
      colorName.value = 'blue';
      break;
    case '#eed1fc':
    case '#b940f1':
    case '#8834b4':
    case '#f6e6fe':
    case 'purple':
      colorName.value = 'purple';
      break;
    case '#f8d5f0':
    case '#e150c1':
    case '#a43f93':
    case 'pink':
      colorName.value = 'pink';
      break;
    case '#e0e1e6':
    case '#83869a':
    case '#55596d':
    case '#eeeff1':
    case '#141725':
    case '#EEEFF1':
    case 'gray':
      colorName.value = 'gray';
      break;
    case '#ffffff':
    case '#14172':
    case 'white':
      colorName.value = 'white';
      break;
    default:
      colorName.value = 'white';
      break;
  }
  const pickedColorInfo = settingsAnalyticalAxes.colorsToPick.find(
    (elt) => elt.color === props.color
  );
  if (pickedColorInfo) {
    tone.value = pickedColorInfo?.tone;
  } else {
    tone.value = 'default';
  }
};
</script>

<style lang="scss" scoped>
.breadcrumb-box {
  position: relative;

  display: flex;
  gap: 4px;
  align-items: center;

  width: fit-content;
  max-width: 100%;
  padding: 4px 8px;

  white-space: nowrap;

  border-radius: 12px;

  > * {
    cursor: v-bind('cursor');
  }

  span {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -0.12px;
  }
}

.red.default {
  color: $red600;
  background: $red100;
}

.red.default.fill-icon :deep(svg path) {
  fill: $red400;
}

.red.primary {
  color: white;
  background: $red500;
}

.red.primary.fill-icon :deep(svg path) {
  fill: $red200;
}

.red.dark {
  color: $red100;
  background: $red700;
}

.red.dark.fill-icon :deep(svg path) {
  fill: $red300;
}

.blue.default {
  color: $blue600;
  background: $blue100;
}

.blue.default.fill-icon :deep(svg path) {
  fill: $blue400;
}

.blue.primary {
  color: white;
  background: $blue500;
}

.blue.primary.fill-icon :deep(svg path) {
  fill: $blue200;
}

.blue.dark {
  color: $blue100;
  background: $blue700;
}

.blue.dark.fill-icon :deep(svg path) {
  fill: $blue300;
}

.pink.default {
  color: $pink600;
  background: $pink100;
}

.pink.default.fill-icon :deep(svg path) {
  fill: $pink400;
}

.pink.primary {
  color: white;
  background: $pink500;
}

.pink.primary.fill-icon :deep(svg path) {
  fill: $pink200;
}

.pink.dark {
  color: $pink100;
  background: $pink700;
}

.pink.dark.fill-icon :deep(svg path) {
  fill: $pink300;
}

.green.default {
  color: $green600;
  background: $green100;
}

.green.default.fill-icon :deep(svg path) {
  fill: $green400;
}

.green.primary {
  color: white;
  background: $green500;
}

.green.primary.fill-icon :deep(svg path) {
  fill: $green200;
}

.green.dark {
  color: $green100;
  background: $green700;
}

.green.dark.fill-icon :deep(svg path) {
  fill: $green300;
}

.orange.default {
  color: $orange600;
  background: $orange150;
}

.orange.default.fill-icon :deep(svg path) {
  fill: $orange400;
}

.orange.primary {
  color: white;
  background: $orange500;
}

.orange.primary.fill-icon :deep(svg path) {
  fill: $orange200;
}

.orange.dark {
  color: $orange100;
  background: $orange700;
}

.orange.dark.fill-icon :deep(svg path) {
  fill: $orange300;
}

.cyan.default {
  color: $cyan700;
  background: $cyan150;
}

.cyan.default.fill-icon :deep(svg path) {
  fill: $cyan500;
}

.cyan.primary {
  color: white;
  background: $cyan500;
}

.cyan.primary.fill-icon :deep(svg path) {
  fill: $cyan200;
}

.cyan.dark {
  color: $cyan100;
  background: $cyan700;
}

.cyan.dark.fill-icon :deep(svg path) {
  fill: $cyan300;
}

.purple.default {
  color: $purple600;
  background: $purple100;
}

.purple.default.fill-icon :deep(svg path) {
  fill: $purple400;
}

.purple.primary {
  color: white;
  background: $purple500;
}

.purple.primary.fill-icon :deep(svg path) {
  fill: $purple200;
}

.purple.dark {
  color: $purple100;
  background: $purple700;
}

.purple.dark.fill-icon :deep(svg path) {
  fill: $purple300;
}

.yellow.default {
  color: $yellow800;
  background: $yellow150;
}

.yellow.default.fill-icon :deep(svg path) {
  fill: $yellow600;
}

.yellow.primary {
  color: $yellow900;
  background: $yellow500;
}

.yellow.primary.fill-icon :deep(svg path) {
  fill: $yellow700;
}

.yellow.dark {
  color: $yellow100;
  background: $yellow700;
}

.yellow.dark.fill-icon :deep(svg path) {
  fill: $yellow400;
}

.gray.default {
  color: $gray1000;
  background: $gray100;
}

.gray.default.fill-icon :deep(svg path) {
  fill: $gray700;
}

.gray.primary {
  color: white;
  background: $gray400;
}

.gray.primary.fill-icon :deep(svg path) {
  fill: $gray200;
}

.gray.dark {
  color: $gray100;
  background: $gray700;
}

.gray.dark.fill-icon :deep(svg path) {
  fill: $gray400;
}

.white.default {
  color: $gray1000;
  background: white;
  box-shadow: inset 0 0 0 1px $gray150;
}

.white.default.fill-icon :deep(svg path) {
  fill: $gray700;
}

.white.dark {
  color: white;
  background: $gray1000;
}

.white.dark.fill-icon :deep(svg path) {
  fill: $gray400;
}

.disabled {
  color: $gray500 !important;
  background: $gray100 !important;
}

.disabled.fill-icon :deep(svg path) {
  fill: $gray400 !important;
}
</style>
