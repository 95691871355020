<template>
  <div
    v-if="node?.nodes?.length"
    style="margin-left: 24px"
  >
    <div
      v-for="(childNode, index) in node.nodes"
      :key="'child-' + childNode.id"
    >
      <AnalyticalAxisChildTitle
        :node="childNode"
        :is-deployed="isDeployed[index]"
        :is-checked="isObjectInArrayByField(axesChecked, 'id', childNode.id)"
        :single-select="singleSelect"
        :filter-by-erp="filterByErp"
        @click.prevent="isDeployed[index] = !isDeployed[index]"
        @update:checkAction="(e) => handleCheckAction(e, index, childNode)"
      />
      <AnalyticalAxisChild
        v-show="isDeployed[index]"
        :ref="(elt) => setItemRef(elt, childNode.id)"
        :node="childNode"
        :axis-name="axisName"
        :single-select="singleSelect"
        :filter-by-erp="filterByErp"
        @update:addParentId="addNodeId(childNode, index)"
        @update:removeParentId="removeNodeId(childNode.id, true)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { inject, onMounted, ref } from 'vue';

import AnalyticalAxisChild from '@/components/Transactions/TransactionCategory/AnalyticalAxisChild.vue';
import AnalyticalAxisChildTitle from '@/components/Transactions/TransactionCategory/AnalyticalAxisChildTitle.vue';
import { AnalyticalAxesChildModel } from '@/custom-types/analytical-axes';
import { isObjectInArrayByField } from '@/helpers/array';

const props = defineProps<{
  node: AnalyticalAxesChildModel;
  singleSelect?: boolean;
  axisName: string;
  filterByErp?: boolean;
}>();
const axesChecked = ref(inject<object[]>('copyOfAxesChecked'));
const radioChecked = ref(inject<number>('radioChecked'));

const isDeployed = ref(new Array(props.node?.nodes?.length).fill(false));

const nodeRefs = ref([]);

const emit = defineEmits([
  'update:addParentId',
  'update:removeParentId',
  'onCloseAxis'
]);

const idTitleChecked = ref([]);

const handleCheckAction = (value, index, node) => {
  updateIdListAxisChecked(value, index, node);
  if (props.singleSelect && value) {
    emit('onCloseAxis');
  }
  if (value) {
    isDeployed.value[index] = value;
  }
};

const updateIdListAxisChecked = (isChecked, index, node) => {
  if (isChecked && props.singleSelect) {
    axesChecked.value = [];
    idTitleChecked.value = [];
  }

  if (isChecked) {
    addNodeId(node, index);
  } else {
    removeNodeId(node.id);
  }
};

const setItemRef = (elt, id) => {
  const current = nodeRefs.value.find((node) => node.id === id);
  if (!current) {
    nodeRefs.value.push({
      id,
      elt
    });
  }
};

const addNodeId = (node, index) => {
  if (!isObjectInArrayByField(axesChecked.value, 'id', node.id)) {
    axesChecked.value = [
      ...axesChecked.value,
      {
        id: node.id,
        name: node.name
      }
    ];
  }

  if (!idTitleChecked.value.includes(node.id)) {
    idTitleChecked.value = [...idTitleChecked.value, node.id];
  }

  if (props.singleSelect) {
    // add parent only for single select
    emit('update:addParentId');
  } else {
    // add child when multi select
    if (idTitleChecked.value.length === props.node.nodes.length) {
      emit('update:addParentId');
    }
    nodeRefs.value[index].elt.addAllNodeId();
  }
};

const removeNodeId = (nodeId, avoidCheckingChild = false) => {
  if (props.singleSelect && !!radioChecked.value) {
    radioChecked.value = null;
  }

  if (isObjectInArrayByField(axesChecked.value, 'id', nodeId)) {
    removeFromArrayObject('id', nodeId);
  }

  if (idTitleChecked.value.includes(nodeId)) {
    removeFromArray(nodeId);
  }

  if (idTitleChecked.value.length < props.node.nodes.length) {
    emit('update:removeParentId');
  }

  if (!avoidCheckingChild) {
    const tmpNode = nodeRefs.value.find((elt) => elt.id === nodeId);
    if (tmpNode) {
      tmpNode.elt.removeAllNodeId();
    }
  }
};

const addAllNodeId = () => {
  props.node.nodes.forEach((node) => {
    if (!isObjectInArrayByField(axesChecked.value, 'id', node.id)) {
      axesChecked.value = [
        ...axesChecked.value,
        {
          id: node.id,
          name: node.name
        }
      ];
    }

    if (!idTitleChecked.value.includes(node.id)) {
      idTitleChecked.value = [...idTitleChecked.value, node.id];
    }
  });
  nodeRefs.value.map((elt) => elt.elt.addAllNodeId());
};

const removeAllNodeId = () => {
  if (props.singleSelect && !!radioChecked.value) {
    radioChecked.value = null;
  }
  props.node.nodes.forEach((node) => {
    if (isObjectInArrayByField(axesChecked.value, 'id', node.id)) {
      removeFromArrayObject('id', node.id);
    }

    if (idTitleChecked.value.includes(node.id)) {
      removeFromArray(node.id);
    }
  });
  nodeRefs.value.map((elt) => elt.elt.removeAllNodeId());
};

const removeFromArrayObject = (field, value) => {
  const index = axesChecked.value.map((e) => e[field]).indexOf(value);
  axesChecked.value.splice(index, 1);
  axesChecked.value = [...axesChecked.value];
};

const removeFromArray = (value) => {
  const index = idTitleChecked.value.indexOf(value);
  idTitleChecked.value.splice(index, 1);
  idTitleChecked.value = [...idTitleChecked.value];
};

const deployAll = () => {
  isDeployed.value = new Array(props.node?.nodes?.length).fill(true);
  nodeRefs.value.forEach((elt) => elt.elt.deployAll());
};

const unDeployAll = () => {
  isDeployed.value = new Array(props.node?.nodes?.length).fill(false);
  nodeRefs.value.forEach((elt) => {
    elt.elt.unDeployAll();
  });
};

onMounted(() => {
  props.node.nodes.forEach((node, index) =>
    isObjectInArrayByField(axesChecked.value, 'id', node.id)
      ? updateIdListAxisChecked(true, index, node)
      : null
  );
});

defineExpose({
  removeNodeId,
  idTitleChecked,
  addAllNodeId,
  removeAllNodeId,
  deployAll,
  unDeployAll
});
</script>

<style lang="scss" scoped>
.child-node {
  cursor: pointer;

  display: flex;

  margin: 8px 0 8px 0;
  margin-left: 30px;
  padding: 4px 8px;

  border: 1px solid #eeeff1;
  border-radius: 8px;
  label {
    cursor: pointer;
    color: $blue500;
  }
}
</style>
